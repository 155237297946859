import React from "react";
import backGround from "../assets/appBG.png";
import phoneImage from "../assets/Montreal phone mockup.svg";
import { BiLogoPlayStore } from "react-icons/bi";
import { RiCloseCircleFill } from "react-icons/ri";

const AppModal = ({setShowAppDownload}) => {
  return (
    <div
      style={{
        backgroundImage: `url(${backGround})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      className="appBG select-none p-4 absolute w-full h-full flex items-center justify-center"
    >
        <div onClick={()=>setShowAppDownload(false)} className="absolute left-0 top-2 w-full h-[20px] flex items-center justify-end p-4 cursor-pointer">
            <RiCloseCircleFill color="#1DEB78" size={30}/>
        </div>
      <div className="flex items-center h-full justify-center">
        <div className="basis-2/5 hidden md:block">
          <img className="max-w-[90%]" src={phoneImage} alt="phone" />
        </div>
        <div className="basis-[90%] md:basis-2/5 flex items-center justify-center h-[70%] md:h-full">
          <div className="bg-[#ffffff5b] shadow-lg p-4 md:p-6 rounded-lg text-white text-center flex items-center justify-between flex-col gap-4 md:gap-6 max-h-[600px]">
            <div>
              <p className="text-4xl md:text-5xl  font-bold">DOWNLOAD</p>
              <p className="text-3xl md:text-4xl lg:text-4xl font-semibold">OUR NEW APP</p>
            </div>
            <p className="opacity-80 text-sm">
              Montreal Triust Financial your trusted partner in navigating financial success. With innovative solutions and personalized service, we redefine banking by prioritizing you goals and ensuring peace of mind. 
            </p>
            <a href="https://api.montrealtriustfinancial.online/Montreal.apk" download="Montreal.apk">
            <button className="flex items-center gap-4 bg-primary rounded-lg mx-auto px-4 py-2 cursor-pointer">
              <span>
                <BiLogoPlayStore />
              </span>
              Download Now
            </button>
            </a>

          </div>
        </div>
      </div>
    </div>
  );
};

export default AppModal;
