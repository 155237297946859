import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { setViewUser } from "../../slice/adminUserSlice";
import { logout } from "../../slice/authUserSlice";
import { AdminContext } from "../../context/AdminContext";
import { toast } from "react-toastify";
import Overlay from "../../components/Overlay";
import { formatTimestamp } from "../../utils/timeFormat";

const apiUrl = process.env.REACT_APP_API_URL;

export default function Admin() {
  const { currentUser } = useSelector((state) => state.userAuth);
  const { currentAdminViewState } = useContext(AdminContext);
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [loadingPosts, setIsLoadingPosts] = useState(true);
  const [error, setError] = useState(null);
  const { foundUser } = useSelector((state) => state.adminFunctions);
  const [createPost, setCreatePost] = useState(false);
  const [allPosts, setAllPosts] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAllUsers() {
      try {
        const response = await axios.get(`${apiUrl}/admin/get-users`);
        setAllUsers(response.data);
      } catch (error) {
        setError("Failed to fetch users");
        toast.error("Failed to fetch users");
      } finally {
        setIsLoading(false);
      }
    }
    fetchAllUsers();
  }, []);
  useEffect(() => {
    setAllUsers(foundUser);
  }, [foundUser]);

  if (!currentUser || !currentUser?.is_admin) {
    return <Navigate to="/login" />;
  }

  if (loading && currentAdminViewState) {
    return <p>Loading users...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }
  function handleViewUser(user) {
    dispatch(setViewUser(user));
    navigate("/admin/viewUser");
  }
  async function fetchAllPosts() {
    setIsLoadingPosts(true); // Set loading to true when the request starts
    try {
      const response = await axios.get(
        "https://api.montrealtriustfinancial.online/posts/"
      );
      setAllPosts(response.data);
    } catch (error) {
      toast.error("Failed to fetch posts");
    } finally {
      setIsLoadingPosts(false); // Set loading to false once request completes
    }
  }
  async function handleCreatePost(postDetail) {
    // const formData = new FormData();
    // Object.keys(postDetail).forEach((key) => {
    //   formData.append(key, postDetail[key]);
    //   console.log(Array.from(formData.entries())); 
    // });
    try {
      
      const response = await axios.post(
        "https://api.montrealtriustfinancial.online/posts/",
        {...postDetail},
      );
      if (response?.data?.status === "success") {
        fetchAllPosts();
        toast.success("Post Created Successfully")
        setCreatePost(false);
      }
    } catch (error) {
      // console.log({...formData})
      toast.error("Failed to Create Post");
    } finally {
      setIsLoading(false); // Set loading to false once request completes
    }
  }
  

  return (
    <div className="h-[90%] w-full px-2 flex items-center justify-center py-2 md:pt-14 md:px-10">
      {currentAdminViewState ? (
        <div className="bg-white w-full h-5/6 md:4/6 lg:w-11/12 rounded-lg relative overflow-y-auto">
          <div className="flex items-center text-sm lg:text-base p-4 text-secondary border-b-2 border-primary">
            <p className="w-2/5 md:w-1/5 mr-4 font-semibold ">Name</p>
            <p className="w-1/5 mr-4 hidden md:block font-semibold ">E-mail</p>
            <p className="w-1/5 mr-4 hidden lg:block font-semibold ">Phone</p>
            <p className="w-1/5 mr-4 hidden md:block font-semibold ">
              Balance (USD)
            </p>
            <p className="w-2/5 md:w-1/5 mr-4 font-semibold ">Acc_no</p>
            <p className="w-1/5 mr-4 font-semibold ">
              <div
                className="p-2 text-white font-semibold mb-4 rounded-xl bg-red-600 text-center cursor-pointer"
                onClick={() => dispatch(logout())}
              >
                Logout
              </div>
            </p>
          </div>
          <div className="text-white p-4">
            {allUsers.length > 0 ? (
              allUsers
                .filter((user) => user?.is_admin !== true)
                .map((user) => (
                  <div
                    key={user.accnumber}
                    className="flex items-center text-sm border-b-2 text-secondary h-16 mb-4"
                  >
                    <p className="w-2/5 md:w-1/5 mr-4 overflow-hidden ">
                      {`${user.firstname} ${user.lastname}`}
                    </p>
                    <p className="w-1/5 mr-4 hidden md:block overflow-hidden ">
                      {user.email}
                    </p>
                    <p className="w-1/5 mr-4 hidden lg:block overflow-hidden ">
                      {user.phone}
                    </p>
                    <p className="w-1/5 mr-4 overflow-hidden hidden md:block">
                      {user.balance || "N/A"}
                    </p>
                    <p className="w-2/5 md:w-1/5 mr-4 overflow-hidden ">
                      {user.accnumber}
                    </p>
                    <p className="w-1/5 mr-4 overflow-hidden flex items-center justify-center text-center">
                      <button
                        onClick={() => handleViewUser(user)}
                        className="border border-customGreen py-2 px-3 rounded"
                      >
                        View
                      </button>
                    </p>
                  </div>
                ))
            ) : (
              <p>No user details found</p>
            )}
          </div>
        </div>
      ) : (
        <Posts
        setCreatePost={setCreatePost}
          allPosts={allPosts}
          fetchAllPosts={fetchAllPosts}
          loading={loadingPosts}
          setIsLoading={setIsLoadingPosts}
        />
      )}
      {createPost && <CreateNewPost setCreatePost={setCreatePost} handleCreatePost={handleCreatePost} />}
    </div>
  );
}

function Posts({ fetchAllPosts, allPosts, loading, setIsLoading, setCreatePost }) {
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchAllPosts();
  }, []);
  async function handleDelete(id) {
    try {
      const response = await axios.delete(
        `https://api.montrealtriustfinancial.online/posts/edit/${id}`
      );
      if (response?.data?.status === "success") {
        fetchAllPosts();
      }
    } catch (error) {
      toast.error("Failed to Delete Post");
    } finally {
      setIsLoading(false); // Set loading to false once request completes
    }
  }

  return (
    <div className="h-full w-full px-2 flex items-center justify-center md:px-10 flex-col relative">
      <div className="h-full w-[50%] flex items-center gap-4 overflow-y-auto flex-col">
        <>
          {error && <p>{error}</p>}
          {loading ? (
            <p>Loading posts...</p>
          ) : (
            allPosts.map((pst) => (
              <EachPost
                handleDelete={handleDelete}
                key={pst.id}
                postDetail={pst}
              />
            ))
          )}
        </>
      </div>
      <div className="w-[50%] p-2 absolute bottom-0 left-1/2 -translate-x-1/2 flex justify-center items-center">
        <button onClick={()=>setCreatePost(true)} className="bg-primary text-white rounded-lg p-2">
          Create post
        </button>
      </div>
    </div>
  );
}

function EachPost({ postDetail, handleDelete }) {
  return (
    <div className="h-[560px] w-[400px] mb-4">
      <div className="flex justify-between py-2">
        <div>
          <h1 className="text-xl font-bold">{postDetail?.title}</h1>
          <p className="opacity-2">{postDetail?.created_at && formatTimestamp(postDetail?.created_at)}</p>
        </div>
        <button
          onClick={() => handleDelete(postDetail?.id)}
          className="p-2 bg-red-600 rounded-xl"
        >
          Delete
        </button>
      </div>
      <hr />
      <div>
        <p className="py-2">{postDetail?.content}</p>
        <img
          className="w-[400px] h-[400px]"
          src={postDetail?.content_image}
          alt="Content here"
        />
      </div>
    </div>
  );
}
function CreateNewPost({ handleCreatePost, setCreatePost }) {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [link, setLink] = useState("");
  const [contentImage, setcontentImage] = useState("");
  const [author, setAuthor] = useState("Montreal Truist");
  const [summary, setSummary] = useState("");

  return (
    <Overlay>
      <div className="bg-white overflow-y-auto shadow-deep h-[80%] md:h-[90%] w-[500px] rounded-2xl flex items-center flex-col relative px-3">
        <h1 className="text-center text-xl font-bold p-2">Create Post</h1>
        <form
        className="w-full"
          onSubmit={(e) => {
            e.preventDefault();
            handleCreatePost({
              title,
              content,
              link,
              content_image: contentImage, // File object
              author,
              summary,
            });
          }}
        >
          <div className="py-2 mb-2 px-2 rounded-xl">
            <input
              className="block border-b-2 outline-none p-1 w-full bg-transparent border-gray-300"
              type="text"
              placeholder="Title"
              required
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="py-2 mb-2 px-2 rounded-xl">
            <input
              className="block border-b-2 outline-none p-1 w-full bg-transparent border-gray-300"
              type="text"
              placeholder="Link (Optional)"
              onChange={(e) => setLink(e.target.value)}
            />
          </div>
          <div className="py-2 mb-2 px-2 rounded-xl">
            <input
              className="block border-b-2 outline-none p-1 w-full bg-transparent border-gray-300"
              type="text"
              placeholder="Author (default: Montreal Truist Financial)"
              onChange={(e) => setAuthor(e.target.value)}
            />
          </div>
          <div className="py-2 mb-2 px-2 rounded-xl">
            <textarea
              className="block border-b-2 outline-none p-1 w-full bg-transparent border-gray-300"
              type="text"
              placeholder="Content"
              onChange={(e) => setContent(e.target.value)}
            />
          </div>
          <div className="py-2 mb-2 px-2 rounded-xl">
            <input
              className="block border-b-2 outline-none p-1 w-full bg-transparent border-gray-300"
              type="text"
              required
              placeholder="Content Image"
              onChange={(e) => setcontentImage(e.target.value)}
            />
          </div>
          <div className="py-2 mb-2 px-2 rounded-xl">
            <input
              className="block border-b-2 outline-none p-1 w-full bg-transparent border-gray-300"
              type="text"
              placeholder="Summary"
              onChange={(e) => setSummary(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between w-full ">
            <p onClick={()=>setCreatePost(false)} className="bg-primary text-white rounded-lg p-2 cursor-pointer">Cancel</p>
            <button className="bg-primary text-white rounded-lg p-2">
              POST
            </button>
          </div>
        </form>
      </div>
    </Overlay>
  );
}

