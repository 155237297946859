import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

const AdminContext = createContext();

const AdminContextProvider = ({ children }) => {
  const [currentAdminViewState, setCurrentAdminViewState] = useState(true);
  

  return (
    <AdminContext.Provider
      value={{ currentAdminViewState, setCurrentAdminViewState }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export { AdminContext, AdminContextProvider };