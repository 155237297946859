export function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
  
    // Extract components
    const year = date.getFullYear();
    const month = date.toLocaleString('default', { month: 'long' }); // Full month name
    const day = date.getDate();
    const hours = date.getHours().toString().padStart(2, '0'); // Add leading zero
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Add leading zero
    const seconds = date.getSeconds().toString().padStart(2, '0'); // Add leading zero
  
    // Format result
    return `${month} ${day}, ${year} - ${hours}:${minutes}:${seconds}`;
  }

  