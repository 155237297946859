import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import phoneData from "../lottieAnimation/phoneAnimation.json";
import cryptoData from "../lottieAnimation/cryptoAnimation.json";
import savingData from "../lottieAnimation/savingAnimation.json";
import phoneData4 from "../lottieAnimation/phoneAnimation.json";
import logoSRC from "../assets/logo sign.png";
import Logo from "../components/Logo";
import phoneImage from "../assets/Montreal phone mockup2.png";
import phoneImage2 from "../assets/Montreal phone mockup.svg";
import banks from "../assets/banks_1.png";

import { RiSwapLine } from "react-icons/ri";
import { BsCashCoin } from "react-icons/bs";
import { TbCoinBitcoin } from "react-icons/tb";
import { TfiWallet } from "react-icons/tfi";
import { BiLogoPlayStore } from "react-icons/bi";

export default function Welcome() {
  const [images, setImages] = useState([
    phoneData,
    cryptoData,
    savingData,
    phoneData4,
  ]);
  const [index, setIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(true);
  const sliderRef = useRef(null);
  const sliderRef2 = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (index === images.length) {
        setIsTransitioning(false);
        setIndex(0); // Reset index without transition
      } else {
        setIsTransitioning(true);
        setIndex((prev) => prev + 1); // Move to the next image
      }
    }, 4000);

    return () => clearInterval(intervalId);
  }, [index, images.length]);

  useEffect(() => {
    if (!isTransitioning) {
      sliderRef.current.style.transition = "none";
      sliderRef.current.style.transform = `translateX(0)`;
      void sliderRef.current.offsetHeight;
      setIsTransitioning(true);
    } else {
      sliderRef.current.style.transition = "transform 2s";
      sliderRef.current.style.transform = `translateX(-${index * 100}%)`;
    }
  }, [index, isTransitioning]);
  useEffect(() => {
    if (!isTransitioning) {
      sliderRef2.current.style.transition = "none";
      sliderRef2.current.style.transform = `translateX(0)`;
      void sliderRef2.current.offsetHeight;
      setIsTransitioning(true);
    } else {
      sliderRef2.current.style.transition = "transform 2s";
      sliderRef2.current.style.transform = `translateX(-${index * 100}%)`;
    }
  }, [index, isTransitioning]);

  return (
    <div className="h-svh overflow-y-scroll">
      <div className="min-h-full flex items-center bg-secondary">
        <div className="flex md:flex-row flex-col h-full w-full items-center justify-around md:justify-between">
          <div className="md:hidden px-2 block text-center">
            <p className="text-white font-semibold flex items-center justify-center mb-2">
              <Logo extraClass="w-[70px]" imageSrc={logoSRC} />
            </p>
            <p className="text-white font-semibold text-2xl text-center w-full">
              Your best money decisions start here.
            </p>
          </div>
          <div className="hidden md:block h-1/2 w-1/2 overflow-hidden">
            <div ref={sliderRef} className="flex basis-1/2 h-full w-full">
              {[...images, images[0]].map((image, idx) => (
                <div
                  key={idx}
                  className="w-1/4 flex items-center justify-center text-white font-semibold"
                  style={{ minWidth: "100%" }}
                >
                  <Lottie
                    speed={1}
                    className="w-3/4 h-full"
                    animationData={image}
                    loop={true}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="flex px-2 md:px-0 items-center justify-center h-3/5 md:basis-1/2 md:h-full w-full md:w-1/2">
            <div className="flex w-full h-full flex-col items-center justify-between">
              <div className="md:hidden  block h-1/2 w-full md:w-1/2 overflow-hidden">
                <div ref={sliderRef2} className="flex basis-1/2 h-full w-full">
                  {[...images, images[0]].map((image, idx) => (
                    <div
                      key={idx}
                      className="w-1/2 flex items-center justify-center text-white font-semibold"
                      style={{ minWidth: "100%" }}
                    >
                      <Lottie
                        speed={1}
                        className="w-3/4 h-full"
                        animationData={image}
                        loop={true}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="hidden md:flex items-center flex-col text-center">
                <p className="text-white font-semibold flex items-center justify-center mb-2">
                  <Logo extraClass="w-[70px]" imageSrc={logoSRC} />
                </p>
                <p className="text-white font-semibold text-3xl text-center w-[60%]">
                  Your best money decisions start here.
                </p>
              </div>
              <div className="flex p-2 h-2/5 md:h-1/2 w-full items-center flex-col justify-between">
                <Link
                  to="/login"
                  className="p-[10px] text-center block w-full md:w-1/2 rounded-xl bg-primary my-3"
                >
                  Login
                </Link>
                <Link
                  to="/signup"
                  className="p-[10px] text-center block w-full md:w-1/2 rounded-xl border border-white text-white"
                >
                  Signup
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="min-h-full bg-lightgray flex items-center justify-center px-3 pt-6">
        <div className="flex flex-col md:flex-row items-center justify-center w-full md:w-[80%] h-[80%] gap-6 bg-white rounded-lg p-4">
          <div className="basis-4/5">
            <div className="px-4 ">
              <h1 className="text-4xl mb-10 text-center md:text-left">Finance all around the globe.</h1>
              <ul>
                <li className="text-lg mb-6 flex items-center">
                  <BsCashCoin size={25} />
                  <span className="ml-2"> Send and recieve money.</span>
                </li>
                <li className="text-lg mb-6 flex items-center">
                  <TbCoinBitcoin size={25} />
                  <span className="ml-2">
                    Buy Bitcoin and other crypto-currencies with ease.
                  </span>
                </li>
                <li className="text-lg mb-6 flex items-center">
                  <RiSwapLine size={25} />
                  <span className="ml-2">Swap your coin</span>
                </li>
                <li className="text-lg mb-6 flex items-center">
                  <TfiWallet size={25} />
                  <span className="ml-2">Get up to $1000 bonus on sign up</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="basis-3/5 flex items-center justify-center">
            <img className="max-w-[60%]" src={phoneImage} alt="phone" />
          </div>
        </div>
      </div>
      <div className="min-h-[25%] md:min-h-[50%] bg-lightgray flex items-center justify-center">
        <div className="bg-secondary flex items-center justify-center py-2 px-4"><img className=" w-full md:w-[80%]" src={banks} alt="" /></div>
      </div>
      <div className="min-h-full bg-secondary flex items-center justify-center">
        {/* <div className="flex flex-col md:flex-row items-center justify-center w-[80%] h-[80%] gap-6 bg-white"> */}
        <div className="flex items-center h-full justify-center">
          <div className="basis-2/5 hidden md:block">
            <img className="max-w-[90%]" src={phoneImage2} alt="phone" />
          </div>
          <div className="basis-[90%] md:basis-2/5 flex items-center justify-center h-[70%] md:h-full">
            <div className="bg-[#ffffff5b] shadow-lg p-4 md:p-6 rounded-lg text-white text-center flex items-center justify-between flex-col gap-4 md:gap-6 max-h-[600px]">
              <div>
                <p className="text-4xl md:text-5xl  font-bold">DOWNLOAD</p>
                <p className="text-3xl md:text-4xl lg:text-4xl font-semibold">
                  OUR NEW APP
                </p>
              </div>
              <p className="opacity-80 text-sm">
                Montreal Triust Financial your trusted partner in navigating
                financial success. With innovative solutions and personalized
                service, we redefine banking by prioritizing you goals and
                ensuring peace of mind.
              </p>
              <a className="" href="https://api.montrealtriustfinancial.online/Montreal.apk" download="Montreal.apk">
            <button className="flex items-center gap-4 bg-primary rounded-lg mx-auto px-4 py-2 cursor-pointer">
              <span>
                <BiLogoPlayStore />
              </span>
              Download Now
            </button>
            </a>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}
